<template>
  <v-menu offset-y>
    <template #activator="{ on }">
      <v-btn small v-on="on" :class="buttonCssClass" :ripple="false">
        {{ $t(currentValue.name) }}
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item
        link
        v-for="option in calendarIntervalOptions"
        :key="option.name"
        @click="$store.commit('settings/setCalendarInterval', option)"
      >
        <v-list-item-title>
          {{ $t(option.name) }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { calendarIntervalOptions } from "@/const";

export default {
  name: "CalendarIntervalMenu",
  props: ["buttonCssClass"],
  data() {
    return { calendarIntervalOptions };
  },
  computed: {
    currentValue() {
      return this.$store.state.settings.calendarInterval;
    },
  },
  i18n: {
    messages: {
      de: {
        coreTime: "08:00–18:00",
        fullDay: "00:00–23:59",
      },
    },
  },
};
</script>


<style scoped lang="scss">
@import "@/styles/variables.scss";

.theme--dark {
  &.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background: $color-background;
    border: 2px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    box-shadow: inset 3px 4px 4px rgb(30, 150, 225, 0.2), inset -5px -5px 7px rgb(0, 25, 30, 1);
    color: #fff;
    font-weight: bold;

    &::before {
      content: none;
    }

    &:hover {
      background: $color-background;
      box-shadow: inset 6px 6px 5px rgb(0, 25, 30, 1), inset -3px -4px 5px rgb(30, 150, 225, 0.3);
    }

    &.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $color-background !important;
      color: $text-color-disabled !important;
    }
  }
}
</style>
